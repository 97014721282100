.menu-popover-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.menu-popover-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 100ms, transform 100ms;
}
.menu-popover-exit {
  opacity: 1;
  transform: translateY(0);
}
.menu-popover-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 100ms, transform 100ms;
}
